import React from 'react';
import "./Footer.scss";
import { FaGithub, FaInstagram, FaDiscord } from 'react-icons/fa';
import { motion } from 'framer-motion';

const currentYear = new Date().getFullYear();
const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className='footer'
    >
      <div className="footer-container">
        <div className="footer-content">
          <div className="copyright">
            <p>
              © {currentYear} All rights reserved. Made by <span>Rodrigo Lima</span>
            </p>
          </div>
          <div className="follow-me">
            <h4>Follow Me</h4>
            <div className="stick"></div>
            <div className="social-icons">
                        <a href="https://instagram.com/_rodrigo.doctor_/" className="social-icon"><FaInstagram /></a>
                        <a href="https://github.com/geniusdev-tech/" className="social-icon"><FaGithub /></a>
                        <a href="https://discord.gg/yyd9TWZh" className="social-icon"><FaDiscord /></a>
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};
export default Footer;
