import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import { motion } from 'framer-motion';
import { FaGithub, FaInstagram, FaDiscord } from 'react-icons/fa';

const Navbar = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.nav
      initial={{ y: -25 }}
      animate={{ y: -5 }}
      transition={{ duration: 0.5 }}
      className={`header ${scroll ? 'active' : ''}`}
    >
      <div className="nav-container">
        <div className="logo">
          <h3>DataSense.IT</h3>
        </div>
        <div className="social-icons">
          <a href="https://instagram.com/_rodrigo.doctor_/" className="social-icon"><FaInstagram /></a>
          <a href="https://github.com/geniusdev-tech/" className="social-icon"><FaGithub /></a>
          <a href="https://discord.gg/yyd9TWZh" className="social-icon"><FaDiscord /></a>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
